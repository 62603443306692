import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import uiSlice from './ui-slice';
import adminSlice from './admin-slice';
import authSlice from './auth-slice';


const rootReducer = combineReducers({
  auth: authSlice.reducer,
  ui: uiSlice.reducer,
  admin: adminSlice.reducer,

});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['admin', 'auth' ] // specify which reducers to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export default store;
