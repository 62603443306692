import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { setAccessToken, setUser } from '../store/auth-slice';
import instance from '../axios';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [user, setUserState] = useState(null); // Define user state
  const [isLoading, setIsLoading] = useState(true);
  const userType = "client";
  
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken(true);
          const response = await instance.post('/auth/verifyToken', { token, userType });
          const data = response.data[0];
          dispatch(setAccessToken(token));
          dispatch(setUser(data)); // Dispatch setUser action to Redux store
          setUserState(data); // Update local user state
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      } else {
        setUserState(null); // Reset local user state
        setIsLoading(false);
      }
    });
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <AuthContext.Provider value={{ isAuthenticated: !!user, user, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
