import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store/index';
import { AuthProvider } from "./contexts/AuthContext"; // Import the AuthProvider
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './firebaseConfig'; // Adjust the import path as needed


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider> 
        <App />
      </AuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();


