import React, { Suspense } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';

const ClientLayout = React.lazy(() => import('./components/shared/ClientLayout'));
const ClientLogin = React.lazy(() => import('./components/shared/Login/ClientLogin'));
const NotFound = React.lazy(() => import('./components/shared/NotFound/NotFound')); // Lazy load the NotFound component
const AboutUs = React.lazy(() => import('./features/AboutUs/AboutUs')); // Lazy load the NotFound component

function App() {
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/login" element={<ClientLogin />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/*" element={<ClientLayout />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 Not Found */}
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;
