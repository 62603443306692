import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",

  initialState: {
    cartIsVisible: false,
    notification: null,
    loading: false,
    scheduleLoading: false,
    gradeLoading: false,
    appLoading: false,
    trayOpen: false,
    showOptions: true,
  },
  reducers: {
    toggle(state) {
      state.cartIsVisible = !state.cartIsVisible;
    },
    showNotification(state, action) {
      state.notification = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
      };
    },
    addLoadingState(state, action) {
      const newItem = action.payload;
      state.loading = newItem.loading;
    },
    addScheduleLoadingState(state, action) {
      const newItem = action.payload;
      state.scheduleLoading = newItem.scheduleLoading;
    },
    addGradeLoadingState(state, action) {
      const newItem = action.payload;
      state.gradeLoading = newItem.loading;
    },
    addAppLoadingState(state, action) {
      const newItem = action.payload;
      state.appLoading = newItem.loading;
    },
    addTrayOpenState(state, action) {
      const newItem = action.payload;
      state.trayOpen = newItem.trayOpen;
    },
    addShowOptionsState(state, action) {
      const newItem = action.payload;
      state.showOptions = newItem.showOptions;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
