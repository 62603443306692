import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production'
    ? 'https://smxsoil.com/api/v2'
    : 'http://localhost:3001/api/v2/';
// console.log(baseURL)
const instance = axios.create({
    baseURL,
    headers: {'Content-Type': 'application/json'}
});

export default instance;




